import axios from 'axios'
import { Loading, Message } from 'element-ui'
import qs from 'qs'


// 配置相同的请求数据，如headers、token
// 添加loading效果
let loading;
//内存中正在请求的数量,每次请求开始加一个，请求结束减一个，当请求个数为0时才使loading消失
let loadingNum = 0;

function startLoading() {
    if (loadingNum == 0) {
        loading = Loading.service({
            lock: true,
            text: '拼命加载中...',
            background: 'rgba(255,255,255,0.5)',
        })
    }
    //请求数量加1
    loadingNum++;
}

function endLoading() {
    //请求数量减1
    loadingNum--
    if (loadingNum <= 0) {
        loading.close()
    }
}

let loadingInstance = null // 加载全局的loading

// 环境的切换
// const baseURL = process.env.NODE_ENV == 'development' ? '/' : '/' /*http://192.168.0.160:15011*/ /*http://192.168.1.136:15011*/
// const baseURL = 'http://ht.sxgkj.com/api.php/';
//创建axios实例
const service = axios.create({
    // baseURL:'http://niu.vvqc.cn',
    //请求超时时间
    // timeout: 5000,
    headers: {
        'Content-Type': 'application/json;charset=utf-8'
    }
})

//request(请求)拦截器
service.interceptors.request.use(config => {
    startLoading();
    // 每次发送请求之前判断是否存在token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况，此处token一般是用户完成登录后储存到localstorage里的
    config.headers['token'] = window.localStorage.getItem('token') || '';


    // 添加时间戳参数，防止浏览器（IE）对get请求的缓存
    if (config.method === 'get') {
        config.params = {
            ...config.params,
            t: new Date().getTime()
        }
    }

    return config;
}, error => {
    endLoading();
    Message.error({ message: '请求超时!' });
    Promise.reject(error)
})



// respone响应拦截器
service.interceptors.response.use(response => {
    endLoading();
    const res = response.data
    console.log("响应拦截", res)
        // 根据返回的code值来做不同的处理(和后端约定)
    if (response.status === 200) {
        if (res.code != 200) {
            Message({
                type: 'error',
                duration: 1000,
                message: res.msg ? res.msg : "接口请求异常",
            })
            return res
        } else {
            return res
        }
    }

}, async err => { // 这里对401、500等不同情况进行处理
    endLoading();
    if (err && err.response) {
        switch (err.response.status) {
            case 400:
                err.message = '请求错误(400)';
                break;
            case 401:
                this.$router.push('/');
                break;
            case 403:
                err.message = '拒绝访问(403)';
                break;
            case 404:
                err.message = '请求出错(404)';
                break;
            case 408:
                err.message = '请求超时(408)';
                break;
            case 500:
                err.message = '服务器错误(500)';
                break;
            case 501:
                err.message = '服务未实现(501)';
                break;
            case 502:
                err.message = '网络错误(502)';
                break;
            case 503:
                err.message = '服务不可用(503)';
                break;
            case 504:
                err.message = '网络超时(504)';
                break;
            case 505:
                err.message = 'HTTP版本不受支持(505)';
                break;
            default:
                err.message = `连接出错(${err.response.status})!`;
        }
    } else {
        err.message = '连接服务器失败!'
    }
    console.log(err)
    message.error(err.message);
    return Promise.reject(err);

})



//按照请求类型对axios进行封装
const api = {
    post(url, data, otherConfig) {
        // return service.post(url, qs.stringify(data), otherConfig);  //转成表提交格式用这个
        return service.post(url,data, otherConfig);
    },
    get(url, data, otherConfig) {
        return service.get(url, { params: data, ...otherConfig });
    },
    put(url, data, otherConfig) {
        return service.post(url, data, otherConfig);
    },
}


export { api }
export default service
